import React from "react"

import { Link } from "gatsby"

import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Kurzy from "../../components/kurzy/kurzy"

import "../../components/layout/layout.scss"
import "../../components/submenutreneri/submenutreneri.scss"
import "../index.scss"
import "../tanecni-kurzy.scss"

import ImgTreneriPixa from "../../images/treneri/jiri-pixa.jpg"

const TanecniKurzProamZacatecnici = () => (

	<Layout>
		<Seo title="Taneční kurz PRO-AM - začátečníci" description="Taneční kurz PRO-AM - začátečníci" />
		<div className="stk-container stk-container--top-margin">
			<h1 className="stk-h1">Taneční kurz PRO-AM - začátečníci</h1>
			<p className="mb-4">Taneční kurz pro&nbsp;zájemce o&nbsp;taneční sport s&nbsp;profesinálním tanečníkem.</p>

			<Row>
				<Col xl="9" className="pb-5">

					<Row className="stk-seminar mb-2 mt-2">
						<Col lg="12" xl="6">
							<div className="stk-seminare__item">
								<span className="stk-seminare__day">PO</span>
								<span className="stk-seminare__time">20:00<br /><span className="stk-seminare__dash">-</span><br />21:30</span>
								<span className="stk-seminare__title">
									<span className="stk-seminare__name">Taneční kurz PRO-AM</span>
									<span className="stk-seminare__subtitle">začátečníci</span>
								</span>
							</div>
						</Col>
					</Row>

					<Row className="stk-seminar__row">
						<Col xl="2" className="stk-h2 pb-2">
							Místo:
						</Col>
						<Col xl="5">
							<p>
								<h3 className="stk-h3">Taneční sál - budova CASABLANCA</h3>
								Vinohradská 184, Praha-Vinohrady, 130&nbsp;00<br />
								<a className="stk-button stk-button--small mt-2" href="https://goo.gl/maps/gviNHcdBLMo6dmwg7" target="_blank" rel="noreferrer">zobrazit na mapě</a>
							</p>
						</Col>
					</Row>

					<Row className="stk-seminar__row">
						<Col xl="2" className="stk-h2">
							Co na sebe:
						</Col>
						<Col xl="10">
							Dámy volnější kalhoty nebo sukni, triko nebo košili.<br />
							Pánové volnější kalhoty, triko nebo košili.
						</Col>
					</Row>

					<Row className="stk-seminar__row">
						<Col xl="2" className="stk-h2">
							Obuv:
						</Col>
						<Col xl="10">
							taneční obuv
						</Col>
					</Row>

					<Row className="stk-seminar__row">
						<Col xl="2" className="stk-h2">
							S kým:
						</Col>
						<Col xl="10">
							V každém kurzu budete mít čtyři profesionální partnery
						</Col>
					</Row>

					<Row className="stk-seminar__row">
						<Col xl="2" className="stk-h2">
							Co se naučíte:
						</Col>
						<Col xl="10">
							Základy všech pěti latinskoamerických i standardních tanců.<br />
							Sambu, rumbu, čaču, jive, paso doble.<br />
							Waltz, tango, valčík, slowfoxtrot, quickstep.
						</Col>
					</Row>

					<Row className="stk-seminar__row">
						<Col xl="2" className="stk-h2">
							Cena:
						</Col>
						<Col xl="10">
							<span className="stk-h2 stk-h2--yellow">4900,-</span> / 12 lekcí (12 x 90 minut)<br />
						</Col>
					</Row>

					<Row className="stk-seminar__row">
						<Col xl="2" className="stk-h2">
							Přihláška:
						</Col>
						<Col xl="10">
							<strong>Jiří Pixa</strong><br />
							<a href="tel:+420602387109">+420 602 387 109</a><br />
							<a href="mailto:jj.pixa@seznam.cz">jj.pixa@seznam.cz</a>
						</Col>
					</Row>

				</Col>

				<Col xl="3" className="pb-5">
					<h2 className="stk-h2 mb-3">Trenérský tým</h2>
					<Link className="stk-submenutreneri__link" activeClassName="stk-submenutreneri__link--active" to="/treneri/jiri-pixa">
						<img className="stk-submenutreneri__image" alt="Jiří Pixa" src={ImgTreneriPixa} />
						Jiří Pixa
					</Link>
				</Col>

			</Row>

			<Kurzy></Kurzy>

		</div>
	</Layout>

)

export default TanecniKurzProamZacatecnici